import React, {Component} from 'react';
import david from "./css/images/David.jpg";

class App extends Component {
    render() {
        return (
            <div id="wrapper">
                <div id="bg"></div>
                <div id="overlay"></div>
                <div id="main">

                    <header id="header">
                        <img className={"mug"} src={david}/>
                        <h1>David J Barnes</h1>
                        <p>Entrepreneurial Technical Leader. Focused on building great teams and amazing software.</p>
                        <nav>
                            <ul>
                                <li><a href="/David-Barnes.pdf" className="icon fa fa-file-pdf-o"><span className="label">Resume</span></a></li>
                                <li><a href="https://www.youtube.com/c/davidjbarnes" className="icon fa-youtube"><span className="label">YouTube</span></a></li>
                                <li><a href="http://www.angel.co/david-barnes" className="icon fa-angellist"><span className="label">Angel.co</span></a></li>
                                <li><a href="http://bitbucket.org/davidjbarnes" className="icon fa-bitbucket"><span className="label">Bitbucket</span></a></li>
                                <li><a href="mailto:hello@davidjbarnes.com" className="icon fa-envelope-o"><span className="label">Email</span></a>
                                </li>
                            </ul>
                        </nav>
                    </header>

                    <footer id="footer">

                    </footer>

                </div>
            </div>
        );
    }
}

export default App;